.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.align-items-center {
  align-items: center !important;
}

.text-center {
  text-align: center !important;
}

.p-relative {
  position: relative !important;
}

.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.chartjs-render-monitor {
  margin: auto;
}

.swal2-container {
  z-index: 10000 !important;
}

.swal2-toast {
  padding: .625em !important;
  flex-direction: row !important;
}

.swal2-toast .swal2-content {
  margin: 0 !important;
}

.swal2-toast .swal2-icon {
  margin: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.inline-text {
  white-space: nowrap;
}

.muuri-item-positioning {
  z-index: 2;
}

.muuri-item-dragging {
  z-index: 3;
  cursor: move;
}

.muuri-item-releasing {
  z-index: 3;
}

.muuri-item-hidden {
  z-index: 0;
}